import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import router from '../src/router/index.js'  // 导入路由器


import Axios from 'axios'
 Vue.prototype.$http = Axios

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router: router  // 添加路由器
}).$mount('#app')
