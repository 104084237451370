import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import VueWechatTitle from 'vue-wechat-title';

Vue.use(VueWechatTitle);
 
export const constantRoutes = [
    {
      path: '/index',
      component: () => import('@/views/indexView'),
      hidden: true,
      meta: {
        title: "川润液压产品微官网"
      } 
    },
    {
      path: '/directoryItem',
      component: () => import('@/views/directoryItem'),
      hidden: true,
      meta: {
        title: "川润液压产品微官网"
      } 
    },  

    {
      path: '/guide',
      component: () => import('@/views/guideView'),
      hidden: true,
      meta: {
        title: "川润液压产品微官网"
      } 
    },  
    {
      path: '/question',
      component: () => import('@/views/questionView'),
      hidden: true,
      meta: {
        title: "川润液压产品微官网"
      } 
    }, 
    {
      path: '/repair',
      component: () => import('@/views/repairView'),
      hidden: true,
      meta: {
        title: "川润液压产品微官网"
      } 
    },
    {
      path: '/old/index',
      component: () => import('@/views/old/indexView'),
      hidden: true,
      meta: {
        title: "川润液压产品微官网"
      } 
    },
    {
      path: '/old/guide',
      component: () => import('@/views/old/guideView'),
      hidden: true,
      meta: {
        title: "川润液压产品微官网"
      } 
    },  
    {
      path: '/old/question',
      component: () => import('@/views/old/questionView'),
      hidden: true,
      meta: {
        title: "川润液压产品微官网"
      } 
    }, 
    {
      path: '/old/repair',
      component: () => import('@/views/old/repairView'),
      hidden: true,
      meta: {
        title: "川润液压产品微官网"
      } 
    }, 
    {
      path: '', 
      redirect: 'index',
      children: [
       {
            path: 'index',
            component: () => import('@/views/indexView'),
            name: 'Index',
            meta: { title: '川润液压产品微官网', icon: 'dashboard', affix: true }
          }
        ]
      },
  ]


// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}


export default new Router({
  mode: 'history', // 去掉url中的# hash
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

