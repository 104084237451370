<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title" />
  </div>
</template>
<script>
export default  {
  name:  'App',
    metaInfo() {
        return {
            title: '川润产品微官网',
            name:'viewport',
            content:'width=device-width, initial-scale=1'
        }
    },
    beforeCreate() {
      document.querySelector('body').setAttribute('style', 'margin:0;')
    },
}
</script>